<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="部门" prop="deptId">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="工种 清扫、绿化、管理" prop="workType" >
        <a-input v-model="form.workType" placeholder="请输入工种 清扫、绿化、管理" />
      </a-form-model-item> -->
      <a-form-model-item label="人数" prop="peopleCount" >
        <a-input v-model="form.peopleCount" placeholder="请输入人数" />
      </a-form-model-item>
      <a-form-model-item label="总工资" prop="salaryTotal" >
        <a-input v-model="form.salaryTotal" placeholder="请输入总工资" />
      </a-form-model-item>
      <a-form-model-item label="总社保(公司缴纳)" prop="socialSecurityTotal" >
        <a-input v-model="form.socialSecurityTotal" placeholder="请输入总社保" />
      </a-form-model-item>
      <a-form-model-item label="总福利" prop="welfareTotal" >
        <a-input v-model="form.welfareTotal" placeholder="请输入总福利" />
      </a-form-model-item>
      <a-form-model-item label="总激励" prop="incentiveTotal" >
        <a-input v-model="form.incentiveTotal" placeholder="请输入总激励" />
      </a-form-model-item>
      <a-form-model-item label="总成本" prop="costTotal" >
        <a-input v-model="form.costTotal" placeholder="请输入总成本(前三栏加总)" />
      </a-form-model-item>
      <a-form-model-item label="服务面积(平方米)" prop="areaService" >
        <a-input v-model="form.areaService" placeholder="请输入服务面积(平方米)" />
      </a-form-model-item>
      <a-form-model-item label="面积单价" prop="areaUnitPrice" >
        <a-input v-model="form.areaUnitPrice" placeholder="请输入面积单价(总成本/面积)" />
      </a-form-model-item>
      <a-form-model-item label="汇总月份" prop="summaryMonth" v-if="this.formType === 1">
        <a-date-picker style="width: 100%" v-model="form.summaryMonth" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSummary, addSummary, updateSummary } from '@/api/iot/summary'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'CreateForm',
  props: {
    deptOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data () {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        deptId: null,
        deptName: null,
        workType: null,
        peopleCount: null,
        salaryTotal: null,
        socialSecurityTotal: null,
        welfareTotal: null,
        incentiveTotal: null,
        costTotal: null,
        areaService: null,
        areaUnitPrice: null,
        summaryMonth: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deptId: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        summaryMonth: [
          { required: true, message: '汇总月份不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        deptId: null,
        deptName: null,
        workType: null,
        peopleCount: null,
        salaryTotal: null,
        socialSecurityTotal: null,
        incentiveTotal: null,
        welfareTotal: null,
        costTotal: null,
        areaService: null,
        areaUnitPrice: null,
        summaryMonth: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSummary(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSummary(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addSummary(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
