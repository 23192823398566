import request from '@/utils/request'

// 查询人员薪资汇总列表
export function listSummary (query) {
  return request({
    url: '/iot/summary/list',
    method: 'get',
    params: query
  })
}

// 查询人员薪资汇总详细
export function getSummary (id) {
  return request({
    url: '/iot/summary/' + id,
    method: 'get'
  })
}

// 新增人员薪资汇总
export function addSummary (data) {
  return request({
    url: '/iot/summary',
    method: 'post',
    data: data
  })
}

// 修改人员薪资汇总
export function updateSummary (data) {
  return request({
    url: '/iot/summary',
    method: 'put',
    data: data
  })
}

// 删除人员薪资汇总
export function delSummary (id) {
  return request({
    url: '/iot/summary/' + id,
    method: 'delete'
  })
}

// 导出人员薪资汇总
export function exportSummary (query) {
  return request({
    url: '/iot/summary/export',
    method: 'get',
    params: query
  })
}

// 新增人员薪资汇总
export function totalMonth (data) {
  return request({
    url: '/iot/summary/totalMonth',
    method: 'post',
    data: data
  })
}

// 获取项目列表
export function projects (query) {
  return request({
    url: '/iot/summary/projects',
    method: 'get',
    params: query
  })
}

export function getProject (query) {
  return request({
    url: '/iot/summary/getProject',
    method: 'get',
    params: query
  })
}
