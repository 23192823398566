var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "部门", prop: "deptId" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.deptId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deptId", $$v)
                  },
                  expression: "form.deptId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "人数", prop: "peopleCount" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入人数" },
                model: {
                  value: _vm.form.peopleCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "peopleCount", $$v)
                  },
                  expression: "form.peopleCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总工资", prop: "salaryTotal" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入总工资" },
                model: {
                  value: _vm.form.salaryTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "salaryTotal", $$v)
                  },
                  expression: "form.salaryTotal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "总社保(公司缴纳)", prop: "socialSecurityTotal" },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入总社保" },
                model: {
                  value: _vm.form.socialSecurityTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "socialSecurityTotal", $$v)
                  },
                  expression: "form.socialSecurityTotal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总福利", prop: "welfareTotal" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入总福利" },
                model: {
                  value: _vm.form.welfareTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "welfareTotal", $$v)
                  },
                  expression: "form.welfareTotal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总激励", prop: "incentiveTotal" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入总激励" },
                model: {
                  value: _vm.form.incentiveTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "incentiveTotal", $$v)
                  },
                  expression: "form.incentiveTotal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总成本", prop: "costTotal" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入总成本(前三栏加总)" },
                model: {
                  value: _vm.form.costTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "costTotal", $$v)
                  },
                  expression: "form.costTotal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "服务面积(平方米)", prop: "areaService" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入服务面积(平方米)" },
                model: {
                  value: _vm.form.areaService,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "areaService", $$v)
                  },
                  expression: "form.areaService",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "面积单价", prop: "areaUnitPrice" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入面积单价(总成本/面积)" },
                model: {
                  value: _vm.form.areaUnitPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "areaUnitPrice", $$v)
                  },
                  expression: "form.areaUnitPrice",
                },
              }),
            ],
            1
          ),
          this.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "汇总月份", prop: "summaryMonth" } },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "YYYY-MM-DD HH:mm:ss",
                      format: "YYYY-MM",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.form.summaryMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "summaryMonth", $$v)
                      },
                      expression: "form.summaryMonth",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }